body {
    background-color: #f6f9ff;
    min-width: 100vh !important;
    min-height: -webkit-fill-available !important;
    display:block;
    min-height: 100%;
}

body#form{
    color: #000;
    overflow: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: darkcyan !important;
}

body#login {
   
}

#app {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh !important;
    height: -webkit-fill-available !important;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: auto;
}

body#login #app {
    display: block;
}

.bg-dark-sidebar {
    background-color: rgba(32,33,35,0.9) !important;
}

sidebar a {
    color: whitesmoke !important;
    text-decoration: none;
}

td.lowkey {
    color: #999 !important;
    font-size: 90%;
}

td.id {
    width: 3em;
}

.text-right {
    text-align: right;
}

.w-button-1 {
    width: 3em;
}

